.errorBanner {
  background-color: #f32013;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  border-radius: 15px;
  margin: 1rem;
  font-size: 14px;
  position: fixed;
  top: 0;
  z-index: 99999;
  width: 70%;
}
.errorButton {
  color: white;
  border-radius: 5px;
  font-weight: 700;
}
