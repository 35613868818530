.dropzone-ui .dz-ui-footer{
font-size: 0.6em !important;
}

.dropzone-ui .dz-ui-header{
    font-size: 0.6em !important;  
}

.madalconfig{
    width: 80% !important;
}